/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { id as e } from "../../kernel.js";
import t from "../../request.js";
function n(e) {
  return f(e) || o(e);
}
function r(e) {
  return null != e && "object" == typeof e && "type" in e && "feature" === e.type;
}
function i(e) {
  return null != e && "object" == typeof e && "type" in e && "graphics" === e.type;
}
function u(e) {
  return null != e && "object" == typeof e && "type" in e && "map-notes" === e.type && "sublayers" in e;
}
function s(e) {
  return null != e && "object" == typeof e && "type" in e && "knowledge-graph" === e.type && "layers" in e;
}
function a(e) {
  return null != e && "object" == typeof e && "type" in e && "link-chart" === e.type && "layers" in e;
}
function o(e) {
  return "scene" === e?.type;
}
function c(e) {
  return null != e && "object" == typeof e && "type" in e && "subtype-group" === e.type && "sublayers" in e;
}
function l(e) {
  return "subtype-sublayer" === e?.type;
}
function f(e) {
  const t = e?.type;
  return "imagery-tile" === t || "tile" === t || "open-street-map" === t || "vector-tile" === t || "web-tile" === t || "wmts" === t;
}
function y(e) {
  const t = e?.type;
  return "base-tile" === t || "tile" === t || "elevation" === t || "imagery-tile" === t || "base-elevation" === t || "open-street-map" === t || "wcs" === t || "web-tile" === t || "wmts" === t || "vector-tile" === t;
}
function p(e) {
  return null != e && "type" in e && "group" === e.type;
}
const g = {
  Point: "SceneLayer",
  "3DObject": "SceneLayer",
  IntegratedMesh: "IntegratedMeshLayer",
  PointCloud: "PointCloudLayer",
  Building: "BuildingSceneLayer"
};
function b(e) {
  const t = e?.type;
  return "building-scene" === t || "integrated-mesh" === t || "point-cloud" === t || "scene" === t;
}
function d(e) {
  return "IntegratedMeshLayer" === e || "IntegratedMesh3DTilesLayer" === e;
}
function m(e) {
  return "integrated-mesh" === e || "integrated-mesh-3dtiles" === e;
}
function h(e) {
  return "voxel" === e?.type;
}
function v(e) {
  return "imagery-tile" === e?.type;
}
function j(e) {
  return null != e?.parent && "declaredClass" in e.parent && "esri.Basemap" === e.parent.declaredClass && e.parent.baseLayers.includes(e);
}
function w(e) {
  return "feature" === e?.type && !e.url && "memory" === e.source?.type;
}
function L(e) {
  return ("feature" === e?.type || "subtype-group" === e?.type) && "feature-layer" === e.source?.type;
}
function E(e) {
  return "feature" === e?.type && "feature-layer" === e.source?.type;
}
function S(e) {
  const t = /[:;]/,
    n = [];
  let r = !1;
  if (e && (e.forEach((e, i) => {
    n.push([i, e]), !r && t.test(e) && (r = !0);
  }), n.length > 0)) {
    let e;
    if (r) {
      const t = {};
      n.forEach(e => {
        t[e[0]] = e[1];
      }), e = JSON.stringify(t);
    } else {
      const t = [];
      n.forEach(e => {
        t.push(e[0] + ":" + e[1]);
      }), e = t.join(";");
    }
    return e;
  }
  return null;
}
function C(e) {
  if (!e) return;
  const t = [];
  return e.forEach((e, n) => {
    t.push('"' + n + '":' + JSON.stringify(e));
  }), t.length ? "{" + t.join(",") + "}" : void 0;
}
function M(e) {
  if (e.activeLayer) {
    const t = e.activeLayer.tileMatrixSet;
    if (t) return t;
    const n = e.activeLayer.tileMatrixSets;
    if (n) return n;
  }
  return null;
}
async function x(n, r) {
  const i = e?.findServerInfo(n);
  if (null != i?.currentVersion) return i.owningSystemUrl || null;
  const u = n.toLowerCase().indexOf("/rest/services");
  if (-1 === u) return null;
  const s = `${n.substring(0, u)}/rest/info`,
    a = null != r ? r.signal : null,
    {
      data: o
    } = await t(s, {
      query: {
        f: "json"
      },
      responseType: "json",
      signal: a
    });
  return o?.owningSystemUrl || null;
}
function I(e) {
  if (!("capabilities" in e)) return !1;
  switch (e.type) {
    case "catalog":
    case "catalog-footprint":
    case "csv":
    case "feature":
    case "geojson":
    case "imagery":
    case "knowledge-graph-sublayer":
    case "ogc-feature":
    case "oriented-imagery":
    case "scene":
    case "sublayer":
    case "subtype-group":
    case "subtype-sublayer":
    case "wfs":
      return !0;
    default:
      return !1;
  }
}
function k(e) {
  return null != e && "object" == typeof e && "isTable" in e && !!e.isTable;
}
function O(e) {
  return I(e) ? "effectiveCapabilities" in e ? e.effectiveCapabilities : e.capabilities : null;
}
function T(e) {
  if (!("editingEnabled" in e)) return !1;
  switch (e.type) {
    case "csv":
    case "feature":
    case "geojson":
    case "oriented-imagery":
    case "scene":
    case "subtype-group":
    case "subtype-sublayer":
      return !0;
    default:
      return !1;
  }
}
function B(e) {
  return !!T(e) && ("effectiveEditingEnabled" in e ? e.effectiveEditingEnabled : e.editingEnabled);
}
export { B as getEffectiveEditingEnabled, O as getEffectiveLayerCapabilities, x as getOwningPortalUrl, M as getTileMaxtrixSetFromActiveLayer, j as isBaseLayer, n as isBasemap3DSupportedLayer, f as isBasemapSupportedTiledLayer, w as isFeatureCollectionLayer, r as isFeatureLayer, E as isFeatureServiceLayer, i as isGraphicsLayer, p as isGroupLayer, v as isImageryTileLayer, m as isIntegratedMeshLayer, d as isIntegratedMeshOperationalLayer, s as isKnowledgeGraphLayer, L as isLayerWithFeatureLayerSource, a as isLinkChartLayer, u as isMapNotesLayer, o as isSceneLayer, b as isSceneServiceLayer, c as isSubtypeGroupLayer, l as isSubtypeSublayer, k as isTable, y as isTiledLayer, h as isVoxelLayer, g as sceneServiceLayerTypeToClassName, S as serializeLayerDefinitions, C as serializeTimeOptions };